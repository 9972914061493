html,
body {
  font-family: 'Amazon Ember';
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  background-color: rgb(227, 225, 214);
}

#home1{
  margin-top: 20px;
  background-color: #D9B08C;
  /* font-style: italic; */
  font-family:Georgia, 'Times New Roman', Times, serif, sans-serif;
  border: 0.2px solid black;
  padding: 10px;
  margin: 15px;
}


/* Scroll Bar */

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(66, 66, 66, 0.2);
  border: 0px;
  background-clip: padding-box;
  border-radius: 5px;
}

nav {
  background-color: #116466;
  padding: 1rem 1rem;
}
/* #febd69 */
#search_btn {
  background-color: #49c5b6;
}

.input-group {
  width: 60%;
  margin-right: 35%;
}

#search_field {
  height: 2.4rem;
  padding: 1rem;
}

#login_btn,
#view_btn,
#prod_btn{
  font-weight: bold;
  background-color: #49c5b6;
  padding: 0.3rem 1.3rem;
  font-family: calibri;
}

#cart_count {
  background-color: #49c5b6;
  padding: 0.1rem 0.1rem;
  border-radius: 0.2rem;
  color: black;
  font-weight: bold;
  font-size: 0.9rem;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 4px;
}

/* Home page */
#products_heading {
  margin-top: 100px;
  font-size: 40px;
  font-weight: 600;
  background-image: conic-gradient(#553c9a, #9035be, #8d48a9);
  color: #116466;
  background-clip: text;
  -webkit-background-clip: text;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.card {
  height: 100%;
  border: 2px solid black;
}

.card-title a {
  color: #2e2e2e;
}

.card-title a:hover {
  color: #D9B086;
  text-decoration: none;
}

.card-body {
  padding-left: 5;
}

.card-text {
  font-size: 1.5rem;
}

#view_btn {
  background-color: #49c5b6;
  border: 1.2px solid black;
  color: black;
}
#view_btn:hover {
  transform: scale(0.85);
  transition-duration: 0.5s;  
}

.card-img-top {
  width: 150px;
  height: 150px;
}

.card-img-top:hover {
 border: 1px solid #D9B086;
 transform: scale(1.225);
 transition-duration: 1s;
}

.ratings {
  font-size: 1.2rem;
  color: #d7ce16;
}

#no_of_reviews {
  font-size: 0.9rem;
  color: grey;
  margin-left: 0.5rem;
}

/* Footer */
footer {
  margin-top: 8rem;
  color: grey;
  bottom: 0;
  width: 100%;
}

/* Ratings */

.rating-outer {
  display: inline-block;
  position: relative;
  font-family: FontAwesome;
  color: #fdcc0d;
}

.rating-outer::before {
  content: '\f006 \f006 \f006 \f006 \f006';
}

.rating-inner {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
}

.rating-inner::before {
  content: '\f005 \f005 \f005 \f005 \f005';
  color: #f8ce0b;
}

/* Product Details Page */
#product_id {
  color: grey;
  font-size: 0.8rem;
}

#product_price {
  font-size: 2rem;
  font-weight: bold;
}

#cart_btn,
#review_btn {
  border-radius: 2rem;
  background-color: #116466;  /*#116466 */
  border: none;
  padding: 0.5rem 2rem;
}

#product_seller {
  color: grey;
  font-size: 0.9rem;
}

#stock_status {
  font-size: 1.1rem;
  font-weight: bold;
  margin-left: 0.3rem;
}

.redColor {
  color: red;
}

.greenColor {
  color: green;
}

#product_image {
  margin-top: 9rem;
}

/* Loader */
.loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  width: 80px;
  height: 80px;
  padding-left: 0;
}
.loader:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #1fb1b6dc;
  border-color: #1fb1b6dc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ////////////////////////////////////////////////  */


.heading-subtile{
  font-size: 25px;
  font-weight: 600;
  background-image:   radial-gradient(ellipse, #14acc4, #47cec5);;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;

}


.heading-tile{
  font-size: 30px;
  font-weight: 600;
  background-image:   radial-gradient(ellipse, #33a2b4, #5cc2bb);;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

/* Cart */

.cart-item {
  margin: 1.8rem 0rem;
  padding: 0rem 0.7rem;
}

.cart-item a {
  color: grey;
}

#delete_cart_item {
  color: red;
  background: white;
  padding: 0.3rem 0.5rem;
  font-size: 1.1rem;
  border: none;
}

#card_item_price {
  color: #8e2aa5;
  font-weight: bold;
  font-size: 1.4rem;
}

#checkout_btn,
.review-btn {
  background-color: #8e2aa5;
  border-color: #8e2aa5;
  margin-top: 2rem;
  border-radius: 5rem;
}

#view_order_details {
  background-color: #49c5b6;
  border-color: #49c5b6;
}

#order_summary {
  border: 1px solid #e3e3e3;
  border-radius: 1rem;
  padding: 2rem 1.5rem;
}

.order-summary-values {
  float: right;
  font-weight: bold;
}

.stockCounter input {
  border: none;
  width: 2rem;
  text-align: center;
}

.plus,
.minus {
  padding: 0.25rem 0.25rem;
}

.stockCounter input::-webkit-outer-spin-button,
.stockCounter input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: white;
}

/* Login & Register */

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  /* margin-left:1rem; */
  font-weight: 700;
}
.wrapper-dummy {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 5rem;

  font-weight: 700;
}



.wrapper form {
  padding: 2.5rem 3rem;
}

.wrapper form .btn {
  background-color: #49c5b6;
  border-color: #49c5b6;
  color: white;
  margin-top: 2.5rem;
}

.wrapper form a {
  font-size: 0.9rem;
  color: grey;
}

/* Avatar */
.avatar {
  display: inline-block;
  margin-bottom: 0;
  height: 3rem;
  width: 3rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rounded-circle {
  border-radius: 50% !important;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

/* Header User Profile */

.avatar-nav {
  margin-right: 0.6rem;
  height: 2.2rem;
  width: 2.2rem;
}

/* Checkout Steps */

.checkout-progress div {
  box-sizing: border-box;
}

.checkout-progress {
  display: block;
  clear: both;
  margin: 20px auto;
  width: auto;
  font-family: sans-serif;
  overflow: auto;
}

.step {
  margin: 0;
  border: 0;
  letter-spacing: 1px;
  line-height: 30px;
  padding: 5px 10px 5px 15px;
  color: grey;
  text-decoration: none;
  cursor: default;
  font-weight: bold;
  float: left;
  height: auto;
}

.incomplete {
  background: #eeeeee;
}

.active-step {
  background: #49c5b6;
  color: #fff;
}

.triangle-active {
  float: left;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 15px solid #49c5b6;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
}

.triangle2-active {
  width: 0;
  float: left;
  border-top: 20px solid #49c5b6;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #49c5b6;
  margin-right: -1px;
}

.triangle-incomplete {
  float: left;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 15px solid #eeeeee;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
}

.triangle2-incomplete {
  width: 0;
  float: left;
  border-top: 20px solid #eeeeee;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #eeeeee;
  margin-right: -1px;
}

/* User Profile */

.avatar-profile {
  height: 16rem;
  width: 16rem;
}

.user-info h4 {
  font-weight: 800;
  color: grey;
  margin-top: 2rem;
}

.user-info p {
  font-size: 1.1rem;
}

#edit_profile {
  background-color: #49c5b6;
  border-color: #49c5b6;
}

/* Confirm Order */

.order-confirm p,
.order-details p {
  margin-left: 1.5rem;
}

.update-btn {
  background-color: #49c5b6;
  border-color: #49c5b6;
  color: white;
}

.update-btn:hover {
  color: white;
  transform: scale(1.25);
  transition-duration: 0.5s;  
}

/* Pagination */
.page-item.active .page-link {
  background-color: #D9B08C;
  border-color: black;
}

.page-link {
  color: #2C3531;
}

.page-link:hover {
  color: #2C3531;
}

/* Admin Sidebar */

.sidebar-wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  min-height: 100vh;
  font-weight: normal;

  margin-top: 0;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
}
#sidebar {
  min-width: 100%;
  max-width: 100%;
  background: #232f3e;
  color: #fff;
  transition: all 0.3s;
}
#sidebar.active {
  margin-left: -250px;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #232f3e;
}
#sidebar ul.components {
  padding: 5px 0px;
  border-bottom: 1px solid #232f3e;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
  color: white;
  text-decoration: none;
}
#sidebar ul li a:hover {
  color: #232f3e;
  background: #fff;
}

#sidebar ul li a i {
  margin-right: 0.3rem;
}

#sidebar ul li.active > a,
a[aria-expanded='true'] {
  color: #fff;
  background: #232f3e;
}
a[data-toggle='collapse'] {
  position: relative;
}
.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 1rem !important;
  padding-left: 30px !important;
  background: #232f3e;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

/* Products List Admin */
#products_table .btn {
  padding: 0.2rem 0.5rem;
  margin-left: 0.4rem;
}

/* Dashboard */

.card-font-size {
  font-size: 1.5rem;
}

/* Reviews */

.rating {
  margin-top: 10rem;
}

.stars {
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: 0;
}

.star {
  display: inline;
  list-style: none;
  font-size: 3rem;
  padding-left: 0.9rem;
  color: #e3e3e3;
}

.star:first-child {
  padding-left: 0;
}

.orange {
  color: #49c5b6;
}

.yellow {
  color: #fdcc0d;
}

.review_user {
  font-size: 0.8rem;
  color: grey;
}




/* //////////////////////////////////// LOGIN /////////////////////////////// */

.animcon
{
	background-color: white;

	overflow: hidden;
	/*overflow hidden because to keep the hand image hidden below*/
	margin-top:20px;
	height: 170px;
	width: 170px;
	border-radius: 50%;
	background-image:url(https://raw.githubusercontent.com/naaficodes/Monkey-Login/master/images/monkey.gif);
	background-size: 90% 85%;
	background-repeat: no-repeat;
	background-position: center;
	box-shadow: 0 10px 25px rgba(0, 0, 0, .2);

	/*flex center to keep the hand image in the center*/
	display: flex;
	align-items: center;
	flex-direction: column;
}
.animcon img
{
	margin-top:110%;
	height: 170px;
	width: 170px;
	transition: 1s;
}

.formcon
{
	margin-top: 38px;
}


/* ///////// */

.dog {
  width: 120px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.body {
  background: #60c8e7;
  border-radius: 300px 300px 0 0;
  height: 300px;
}
.beard {
  width: 55px;
  height: 65px;
  background: #087ba5;
  margin: 0 auto;
  border-radius: 20px;
  position: relative;
}
.beard:after {
  content: '';
  background: #087ba5;
  width: 26px;
  height: 13px;
  border-radius: 0 0 25px 25px;
  display: block;
  margin: 0 auto;
  transform: translateY(64px);
}
.beard:before {
  content: '';
  background: #000;
  width: 20px;
  height: 10px;
  border-radius: 0 0 20px 20px;
  display: block;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.mouth {
  background: #1a243c;
  width: 30px;
  height: 15px;
  border-radius: 0 0 30px 30px;
  position: absolute;
  top: 22px;
  margin: auto;
  left: 0;
  right: 0;
  border-top: 3px solid #fff;
  box-sizing: border-box;
}
.mouth .tongue {
  width: 20px;
  height: 30px;
  border-radius: 5px 5px 10px 10px;
  background: #ff6b5a;
  margin: 0 auto;
  position: relative;
  top: 4px;
  transition: 300ms;
  animation: grow 0.1s infinite alternate;
}
.eyes {
  background: #fff;
  width: 20px;
  height: 24px;
  border-radius: 20px 20px 0 0;
  display: block;
  margin: 0 auto;
  position: relative;
  top: 2px;
}
.eyes:before, .eyes:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #000;
  display: block;
  border-radius: 50%;
  top: 50%;
  position: absolute;
  animation: squeeze 1.2s infinite;
}
.eyes:before {
  left: -13px;
}
.eyes:after {
  right: -13px;
}
.belt {
  height: 30px;
  background: #ff6b5a;
  margin-top: 20px;
  position: relative;
}
.belt .locket {
  width: 30px;
  height: 30px;
  background: #ffc442;
  margin: 0 auto;
  border-radius: 50%;
  transform: translateY(50%);
}
.belt .dot {
  width: 10px;
  height: 10px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto;
}
.belt .dot.dot1 {
  margin-left: 10px;
}
.belt .dot.dot2 {
  margin-left: 34px;
}
.belt .dot.dot3 {
  margin-left: 77px;
}
.belt .dot.dot4 {
  margin-left: 100px;
}
.belt:before, .belt:after {
  content: '';
  position: absolute;
  width: 5px;
  height: 10px;
  background: #fff;
  top: 0;
  bottom: 0;
  margin: auto;
}
.belt:before {
  left: -5px;
  border-radius: 10px 0 0 10px;
}
.belt:after {
  right: -5px;
  border-radius: 0 10px 10px 0;
}
.stomach {
  width: 56px;
  height: 130px;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 120px 120px 0 0;
}
.legs {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 12px;
}
.legs:before, .legs:after {
  height: 12px;
  border-radius: 20px 20px 0 0;
  display: inline-block;
  content: '';
  position: absolute;
  background: #087ba5;
  width: 45px;
}
.legs:before {
  left: -37px;
}
.legs:after {
  right: -37px;
}
.legs .left, .legs .right {
  display: inline;
  width: 12px;
  height: 55px;
  background: #087ba5;
  position: absolute;
  bottom: 0;
}
.legs .left:before, .legs .right:before {
  content: '';
  width: 28px;
  height: 12px;
  background: #60c8e7;
  border-radius: 20px 20px 0 0;
  display: inline-block;
  bottom: 0;
  position: absolute;
}
.legs .left {
  left: -12px;
  border-radius: 20px 0 0 0;
}
.legs .left:before {
  transform: translateX(-1px);
}
.legs .right {
  right: -12px;
  border-radius: 0 20px 0 0;
  z-index: 1;
}
.legs .right:before {
  transform: translateX(-13px);
}
.ears {
  position: absolute;
  z-index: -1;
  top: -20px;
}
.ears:after, .ears:before {
  content: '';
  display: inline-block;
  background: #087ba5;
  width: 40px;
  height: 90px;
  border-radius: 100% 100% 0 0;
}
.ears:after {
  transform: translateX(40px);
  border: 10px solid #60c8e7;
  box-sizing: border-box;
}
.tail {
  background: #60c8e7;
  width: 14px;
  height: 80px;
  position: absolute;
  z-index: -1;
  right: 10px;
  bottom: 40px;
  transform: rotate(30deg);
  border-radius: 14px;
  transform-origin: bottom;
  transition: 300ms;
  animation: shake 0.08s infinite alternate;
}
.tag {
  border-left: 5px solid #ff6b5a;
  border-bottom: 5px solid #ff6b5a;
  width: 200%;
  height: 161px;
  z-index: 2;
  position: absolute;
  top: 30px;
  left: 100px;
  box-sizing: border-box;
  border-bottom-left-radius: 40px;
}
@keyframes squeeze {
  90% {
    transform: none;
    animation-timing-function: ease-in;
 }
  93% {
    transform: scale(1.2, 0.3);
 }
  100% {
    animation-timing-function: ease-out;
 }
}
@keyframes grow {
  0% {
    animation-timing-function: ease-in;
 }
  100% {
    height: 35px;
 }
}
@keyframes shake {
  0% {
    transform: rotate(30deg);
 }
  100% {
    transform: rotate(40deg);
 }
}




.footer-distributed{
	background: rgb(19, 19, 19);
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
	box-sizing: border-box;
	width: 100%;
	text-align: left;
	font: bold 16px sans-serif;
	padding: 55px 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
	display: inline-block;
	vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left{
	width: 40%;
}

/* The company logo */

.footer-distributed h3{
	color:  #ffffff;
	font: normal 36px 'Open Sans', cursive;
	margin: 0;
}

.footer-distributed h3 span{
	color:  #febd69;
}

/* Footer links */


.footer-distributed .footer-company-name{
	color:  #222;
	font-size: 14px;
	font-weight: normal;
	margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center{
	width: 35%;
}

.footer-distributed .footer-center i{
	background-color:  #33383b;
	color: #ffffff;
	font-size: 25px;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	text-align: center;
	line-height: 42px;
	margin: 10px 15px;
	vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope{
	font-size: 17px;
	line-height: 38px;
}

.footer-distributed .footer-center p{
	display: inline-block;
	color: #ffffff;
  font-weight:400;
	vertical-align: middle;
	margin:0;
}

.footer-distributed .footer-center p span{
	display:block;
	font-weight: normal;
	font-size:14px;
	line-height:2;
}

.footer-distributed .footer-center p a{
	color:  #febd69;
	text-decoration: none;;
}

.footer-distributed .footer-links a:before {
  content: "|";
  font-weight:300;
  font-size: 20px;
  left: 0;
  color: #fff;
  display: inline-block;
  padding-right: 5px;
}



/* Footer Right */

.footer-distributed .footer-right{
	width: 20%;
}

.footer-distributed .footer-company-about{
	line-height: 20px;
	color:  #92999f;
	font-size: 13px;
	font-weight: normal;
	margin: 0;
}

.footer-distributed .footer-company-about span{
	display: block;
	color:  #ffffff;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
}

.footer-distributed .footer-icons{
	margin-top: 25px;
}

.footer-distributed .footer-icons a{
	display: inline-block;
	width: 35px;
	height: 35px;
	cursor: pointer;
	background-color:  #33383b;
	border-radius: 2px;

	font-size: 20px;
	color: #ffffff;
	text-align: center;
	line-height: 35px;

	margin-right: 3px;
	margin-bottom: 5px;
}



@media (max-width: 880px) {

	.footer-distributed{
		font: bold 14px sans-serif;
	}

	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed .footer-right{
		display: block;
		width: 100%;
		margin-bottom: 40px;
		text-align: center;
	}

	.footer-distributed .footer-center i{
		margin-left: 0;
	}

}
